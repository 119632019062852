<template>
  <v-data-table
    dense
    :loading="loading"
    :loading-text="$t('loading')"
    :headers="headers"
    :items="parts"
    :footer-props="{
      'items-per-page-options': [10],
      'items-per-page-text': $t('table.per-page')
    }"
    :no-data-text="$t('table.no-data')"
    :orders-per-page="10"
  >
    <template #[`item.actions`]="{ item }">
      <v-icon small @click="deleteItem(item)">
        mdi-delete
      </v-icon>
    </template>
    <template #[`item.material_ro.type`]="{ item }">
      {{ $t(`materials.types.${item.material_ro.type}`) }}
    </template>
  </v-data-table>
</template>

<script>
import MaterialService from '@/services/MaterialService.js';
import OrderService from '@/services/OrderService.js';

export default {
  props: {
    order: {
      type: Object,
      required: true
    },
    epoch: {
      type: Number,
      default: 0
    },
    disableDelete: {
      type: Boolean
    }
  },
  data: function() {
    return {
      parts: [],
      loading: false
    };
  },
  computed: {
    headers() {
      let h = [
        {
          text: this.$t('materials.name'),
          value: 'material_ro.name',
          sortable: true
        },
        {
          text: this.$t('materials.type'),
          value: 'material_ro.type',
          sortable: true
        },
        {
          text: this.$t('materials.part-id'),
          value: 'part_id',
          sortable: true
        }
      ];

      if (!this.disableDelete && this.hasPermission('api.change_order')) {
        h.push({
          text: this.$t('actions'),
          value: 'actions',
          sortable: false
        });
      }
      return h;
    }
  },
  watch: {
    order: {
      handler() {
        this.loadParts();
      },
      deep: true
    },
    epoch() {
      this.loadParts();
    }
  },
  mounted() {
    this.loadParts();
  },
  methods: {
    async loadParts() {
      console.log('loading parts; order:', this.order);
      this.loading = true;

      const options = {
        filters: [{ field: 'orders__id', operation: '=', value: this.order.id }]
      };
      try {
        [[this.parts, this.total]] = await Promise.all([
          MaterialService.GetParts(options, this.search)
        ]);
        console.log('parts:', this.parts);
      } catch (err) {
        this.showError(this, err);
      } finally {
        this.loading = false;
      }
    },
    async deleteItem(item) {
      console.log(
        `remove material part ${item.part_id} from order ${this.order.id}`
      );
      const res = await this.$swal({
        html: this.$t('materials.part-delete-confirmation'),
        showCancelButton: true,
        confirmButtonText: this.$t('yes'),
        cancelButtonText: this.$t('no')
      });

      if (res.isDismissed) {
        return;
      }

      try {
        await OrderService.RemoveMaterialPart(this.order, item.part_id);
        const idx = this.parts.indexOf(item);
        this.parts.splice(idx, 1);
        this.$emit('removed');
      } catch (err) {
        this.showError(this, err);
      }
    }
  }
};
</script>

<style></style>
