<template>
  <v-container cols="12" class="pl-1 pr-1 mt-5 mb-5">
    <v-row>
      <v-col cols="6" sm="4" md="3" class="pa-0 ma-0">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{ $t('orders.item-no') }}</v-list-item-title>
            <v-list-item-subtitle>{{ item.item_no }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="6" sm="3" md="3" class="pa-0 ma-0">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{ $t('orders.duplex') }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ yes_no_val(item.duplex) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>

      <v-col cols="12" sm="6" md="6" class="pa-0 ma-0">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{ $t('orders.braille') }}</v-list-item-title>
            <v-list-item-subtitle>{{ braille }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>

      <v-col cols="6" sm="4" md="3" class="pa-0 ma-0">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{
              $t('orders.cardboard-thickness')
            }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ item.cardboard_thickness }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>

      <v-col cols="6" sm="3" md="3" class="pa-0 ma-0">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{
              $t('orders.field.varnish_free_area')
            }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ yes_no_val(item.varnish_free_area) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>

      <v-col
        v-if="item.varnish_free_area"
        cols="6"
        sm="3"
        md="3"
        class="pa-0 ma-0"
      >
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{
              $t('orders.field.varnish_plate_number')
            }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ item.varnish_plate_number }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>

      <v-col cols="6" sm="3" md="3" class="pa-0 ma-0">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{ $t('orders.pms') }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ yes_no_val(item.pms) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>

      <v-col cols="6" sm="3" md="3" class="pa-0 ma-0">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{
              $t('orders.serialization')
            }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ yes_no_val(item.serialization) }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>

      <v-col cols="12" sm="6" md="6" class="pa-0 ma-0">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{ $t('materials.parts') }}</v-list-item-title>
            <v-list-item-subtitle>
              <materials :order="item" />
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import materials from '@/views/dashboard/components/orders/materialList.vue';

export default {
  components: {
    materials
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data: function() {
    return {};
  },
  computed: {
    braille() {
      if (!this.item.braille_id) {
        return this.$t('no');
      }
      let val = this.item.braille_id;
      if (!this.item.braille_ro) {
        val += ` (not found)`;
      } else {
        val += ` status: ${this.item.braille_ro.status}`;
      }
      val += `, text: ${this.item.braille_text}`;

      return val;
    }
  },
  methods: {
    yes_no_val(v) {
      if (v == null) return this.$t('n/a');
      if (v === true) return this.$t('yes');
      return this.$t('no');
    },

    val(item, field) {
      let v = item[field];
      if (v && v.length > 0) {
        return v;
      }
      return '----';
    },

    whenCreated(item) {
      return new Date(item.when_created).toLocaleDateString();
    },

    dueDate(item) {
      if (!item.due_date) return '';
      return new Date(item.due_date).toLocaleDateString();
    }
  }
};
</script>

<style></style>
